export const eventsData = [
  [
    {
      name: "Dodoco's Boom-Bastic Escapades",
      pos: '0% 40%',
      image: 'Dodocos Boom-Bastic Escapades.png',
      start: '2024-09-09 06:00:00',
      end: '2024-09-23 03:59:59',
      color: '#fef7e4',
      zoom: '250%',
      url: 'https://www.hoyolab.com/article/33001020',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Traces of Artistry',
      pos: '80% 40%',
      image: 'Traces of Artistry.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-09-16 03:59:59',
      color: '#f4e9a7',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32519656',
      showOnHome: true,
    },
    {
      name: 'Ley Line Overflow',
      pos: '0% 20%',
      image: 'leyline_overflow.jpg',
      start: '2024-09-16 04:00:00',
      end: '2024-09-23 03:59:59',
      color: '#5baced',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/33249105',
      showOnHome: true,
    },
    {
      name: 'Mementos of Teyvat',
      pos: '0% 20%',
      image: 'Mementos of Teyvat tmp.png',
      start: '2024-09-28 06:00:00',
      end: '2024-10-08 03:59:59',
      color: '#5489ff',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/33001020',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Bing-Bang Finchball',
      pos: '0% 50%',
      image: 'Bing-Bang Finchball.png',
      start: '2024-08-06 10:00:00',
      end: '2024-08-19 03:59:59',
      color: '#c2e62b',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/31777295',
      showOnHome: true,
    },
    {
      name: 'Brilliant Dawn - Daily Login',
      pos: '0% 50%',
      image: 'Brilliant Dawn.png',
      start: '2024-08-30 04:00:00',
      end: '2024-09-18 03:59:59',
      color: '#ff8d9e',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32554889',
      showOnHome: true,
    },
    {
      name: 'Of Thorns and Crowns',
      pos: '0% 50%',
      image: 'Of Thorns and Crowns.png',
      start: '2024-09-20 10:00:00',
      end: '2024-09-30 03:59:59',
      color: '#f0954d',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/33358228',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Outside the Canvas, Inside the Lens: Dew-Kissed Chapter',
      pos: '0% 20%',
      image: 'Outside the Canvas, Inside the Lens Dew-Kissed Chapter.png',
      start: '2024-07-29 10:00:00',
      end: '2024-08-08 03:59:59',
      color: '#b1d1e6',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/31507365',
      showOnHome: true,
    },
    {
      name: 'Energy Amplifier: Lemma',
      pos: '0% 50%',
      image: 'energy_amplifier_fruition.jpg',
      start: '2024-08-12 10:00:00',
      end: '2024-08-26 03:59:59',
      color: '#41b6c8',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/31677110',
      showOnHome: true,
    },
    {
      name: 'Where the Springs Return',
      pos: '0% 20%',
      image: 'Where the Springs Return.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:59',
      color: '#a0bbef',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32490648',
      showOnHome: true,
    },
    {
      name: "Yupanqui's Turnfire",
      pos: '0% 20%',
      image: 'Yupanquis Turnfire.jpg',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:59',
      color: '#a8d3e7',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32490648',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Summertide Scales and Tales',
      pos: '0% 0%',
      image: 'Summertide Scales and Tales.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-27 03:59:59',
      color: '#64b1e8',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/31070415',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Flowers Resplendent on the Sun-Scorched Sojourn',
      pos: '0% 25%',
      image: 'Flowers Resplendent on the Sun-Scorched Sojourn.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-10-08 14:59:59',
      color: '#ea7745',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/32490648',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'In the Name of the Rosula - Navia Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'In the Name of the Rosula 2.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-06 17:59:00',
      color: '#f6e195',
      url: 'https://www.hoyolab.com/article/31070419',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Ambrosial Essence - Emilie Banner',
      pos: '20% 20%',
      zoom: '200%',
      image: 'Ambrosial Essence 1.png',
      start: '2024-08-06 18:00:00',
      end: '2024-08-27 14:59:00',
      color: '#cde8a4',
      url: 'https://www.hoyolab.com/article/31676908',
      showOnHome: true,
    },
    {
      name: 'Sharktacular Surfari - Mualani Banner',
      pos: '20% 25%',
      zoom: '200%',
      image: 'Sharktacular Surfari 1.png',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:00',
      color: '#6fc5e2',
      url: 'https://www.hoyolab.com/article/32490651',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Seeker of Flame-Wrought Secrets - Kinich Banner',
      pos: '20% 25%',
      zoom: '200%',
      image: 'Seeker of Flame-Wrought Secrets 1.png',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:00',
      color: '#53dcb9',
      url: 'https://www.hoyolab.com/article/33183044',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Twirling Lotus - Nilou Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'Twirling Lotus 3.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-06 17:59:00',
      color: '#b2e0f0',
      url: 'https://www.hoyolab.com/article/31070419',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Discerner of Enigmas - Yelan Banner',
      pos: '20% 20%',
      zoom: '200%',
      image: 'Discerner of Enigmas 4.png',
      start: '2024-08-06 18:00:00',
      end: '2024-08-27 14:59:00',
      color: '#7de6fd',
      url: 'https://www.hoyolab.com/article/31676908',
      showOnHome: true,
    },
    {
      name: 'Leaves in the Wind - Kazuha Banner',
      pos: '20% 20%',
      zoom: '200%',
      image: 'Leaves in the Wind 5.png',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:00',
      color: '#8FFFDE',
      url: 'https://www.hoyolab.com/article/32490651',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Reign of Serenity - Raiden Shogun Banner',
      pos: '20% 18%',
      zoom: '200%',
      image: 'Reign of Serenity 5.png',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:00',
      color: '#D0AEF2',
      url: 'https://www.hoyolab.com/article/33183044',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 67.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-06 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/31070419',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '30% 20%',
      zoom: '200%',
      image: 'Epitome Invocation 68.png',
      start: '2024-08-06 18:00:00',
      end: '2024-08-27 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/31676908',
      showOnHome: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 40%',
      zoom: '200%',
      image: 'Epitome Invocation 69.png',
      start: '2024-08-28 06:00:00',
      end: '2024-09-17 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/32490651',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 70.png',
      start: '2024-09-17 18:00:00',
      end: '2024-10-08 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/33183044',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-07-16 04:00:00',
      end: '2024-08-16 04:00:00',
      color: '#4299E1',
      description:
        'After this character receives healing, their Elemental Skill DMG is increased by 20%. Lasts for 8s, up to 3 stacks. Each stack duration is calculated independently',
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-08-16 04:00:00',
      end: '2024-09-16 04:00:00',
      color: '#4299E1',
      description:
        'When characters attack Burning opponents, their CRIT Rate is increased by 15% and their CRIT DMG is increased by 30%.',
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-09-16 04:00:00',
      end: '2024-10-16 04:00:00',
      color: '#4299E1',
      description:
        "After the active character uses an Elemental Skill, Normal Attack and Elemental Skill DMG is increased by 40% for 12s. For characters in Nightsoul's Blessing, this effect is enhanced: Normal Attack and Elemental Skill DMG is increased by 80%.",
    },
  ],
  [
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-08-01 04:00:00',
      end: '2024-09-01 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Hydro, Cryo, and Anemo. All characters in the party gain a 75% Dendro DMG Bonus',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-09-01 04:00:00',
      end: '2024-10-01 04:00:00',
      color: '#777df3',
      description: 'Required Elemental Types: Pyro, Hydro, and Dendro.',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-10-01 04:00:00',
      end: '2024-11-01 04:00:00',
      color: '#777df3',
      description: 'Required Elemental Types: Pyro, Electro, and Geo.',
    },
  ],
  [
    {
      name: 'Battle Pass - Fanciful Fabrication',
      pos: '0% 45%',
      image: 'Fanciful Fabrication.jpg',
      start: '2024-07-17 06:00:00',
      end: '2024-08-26 03:59:59',
      color: '#8b9fd1',
      url: 'https://www.hoyolab.com/article/31070697',
      timezoneDependent: true,
    },
    {
      name: 'Battle Pass - Primal Fire',
      pos: '0% 45%',
      image: 'Primal Fire.jpg',
      start: '2024-08-28 06:00:00',
      end: '2024-10-07 03:59:59',
      color: '#b2f2eb',
      url: 'https://www.hoyolab.com/article/32490814',
      timezoneDependent: true,
    },
  ],
];
